<template>
  <b-modal id="consultingChecklistModal" :title="title" size="lg" @show="onShow">
    <b-form @submit.stop.prevent="">
      <b-form-row>
        <b-col cols="4">
          <label>Kurs:</label>
          <b-form-input v-model="course" placeholder="Kursbezeichnung..." trim/>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col cols="2">
          <label>SGZ:</label>
          <b-form-select v-model="selectOneId" :options="inputSelectOptions"/>
        </b-col>
        <b-col cols="2">
          <label>SZNG:</label>
          <b-form-select v-model="selectTwoId" :options="inputSelectOptions"/>
        </b-col>
        <b-col cols="2">
          <label>Firmen:</label>
          <b-form-select v-model="selectThreeId" :options="inputSelectOptions"/>
        </b-col>
        <b-col cols="2">
          <label>SGB II:</label>
          <b-form-select v-model="selectFourId" :options="inputSelectOptions"/>
        </b-col>
        <b-col cols="2">
          <label>SGB IX / SGB VII:</label>
          <b-form-select v-model="selectFiveId" :options="inputSelectOptions"/>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col>
          <label>Sonstige mögliche Förderung:</label>
          <b-form-textarea v-model="courseNoteOther" rows="3"/>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col>
          <label>Notiz:</label>
          <b-form-textarea v-model="courseNote" rows="4"/>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col>
          <label>Änderungsverlauf:</label>
          <b-form-textarea v-model="changeLog" rows="8"/>
        </b-col>
      </b-form-row>
    </b-form>
    <template #modal-footer="{ cancel }">
      <b-button v-if="method === 'edit'" variant="outline-danger" class="mr-auto" @click="removeRow()">
        <b-spinner v-show="loadingRemoveButton" small/>
        Löschen
      </b-button>
      <b-button :variant="variantDanger" @click="cancel()">Abbrechen</b-button>
      <b-button :variant="variantSuccess" @click="ok" :disabled="disabledButton">
        <b-spinner v-show="loadingOkButton" small/>
        OK
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {getRequest, putRequest} from "@/modules/requests";
import {mapGetters} from "vuex";

export default {
  name: "ConsultingChecklistModal",
  props: ['method', 'courseEntry'],
  data() {
    return {
      id: null,
      course: '',
      courseNote: '',
      courseNoteOther: '',
      changeLog: '',
      selectOneId: null,
      selectTwoId: null,
      selectThreeId: null,
      selectFourId: null,
      selectFiveId: null,
      loadingOkButton: false,
      loadingRemoveButton: false,
      inputSelectOptions: []
    }
  },
  watch: {
    method() {
      this.onShow()
    },
    courseEntry() {
      this.onShow()
    }
  },
  methods: {
    ok() {
      switch(this.method) {
        case 'edit':
          this.updateRow();
          break;
        case 'add':
          this.addRow();
          break;
      }
    },
    onShow() {
      if(this.method === 'edit' && this.courseEntry) {
        this.id = this.courseEntry.id
        this.course = this.courseEntry.course
        this.courseNote = this.courseEntry.note
        this.courseNoteOther = this.courseEntry.note_other
        this.changeLog = this.courseEntry.changelog
        this.selectOneId = this.courseEntry.column_one.id
        this.selectTwoId = this.courseEntry.column_two.id
        this.selectThreeId = this.courseEntry.column_three.id
        this.selectFourId = this.courseEntry.column_four.id
        this.selectFiveId = this.courseEntry.column_five.id
      }
      else {
        this.reset()
      }
    },
    reset() {
      this.id = null
      this.course = ''
      this.courseNote = ''
      this.courseNoteOther = ''
      this.changeLog = ''
      this.selectOneId = null
      this.selectTwoId = null
      this.selectThreeId = null
      this.selectFourId = null
      this.selectFiveId = null
    },
    refresh() {
      this.$emit('refresh');
    },
    async addRow() {
      this.loadingOkButton = true

      let data = {
        course: this.course,
        column_one_id: this.selectOneId,
        column_two_id: this.selectTwoId,
        column_three_id: this.selectThreeId,
        column_four_id: this.selectFourId,
        column_five_id: this.selectFiveId,
        note: this.courseNote,
        note_other: this.courseNoteOther,
        changelog: this.changeLog
      }

      await putRequest('consulting/admin/add', data, this, 'Neuer Eintrag wurde erfolgreich erstellt.', 'Neuer Eintrag konnte nicht erstellt werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('consultingChecklistModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })
    },
    async updateRow() {
      this.loadingOkButton = true

      let data = {
        consulting_checklist_id: this.id,
        course: this.course,
        column_one_id: this.selectOneId,
        column_two_id: this.selectTwoId,
        column_three_id: this.selectThreeId,
        column_four_id: this.selectFourId,
        column_five_id: this.selectFiveId,
        note: this.courseNote,
        note_other: this.courseNoteOther,
        changelog: this.changeLog
      }

      await putRequest('consulting/admin/update', data, this, 'Der Eintrag wurde erfolgreich aktualisiert.', 'Der Eintrag konnte nicht aktualisiert werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('consultingChecklistModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })
    },
    async removeRow() {
      this.loadingRemoveButton = true
      await putRequest('consulting/admin/delete/' + this.id, null, this, 'Der Eintrag wurde erfolgreich gelöscht.', 'Der Eintrag konnte nicht gelöscht werden.')
          .then(() => {
            this.loadingRemoveButton = false
            this.refresh();
            this.$bvModal.hide('consultingChecklistModal')
          })
          .catch(() => {
            this.loadingRemoveButton = false
          })
    },
    async getColumnValues() {
      await getRequest('consulting/columns', null, this)
          .then((response) => {
            response.data.forEach(element => {
              this.inputSelectOptions.push({
                value: element.id,
                text: element.displayname
              })
            })
          })
    },
  },
  computed: {
    title() {
      if(this.method === 'edit') {
        return 'Eintrag ändern'
      }
      return 'Eintrag hinzufügen'
    },
    disabledButton() {
      return !(this.course && this.selectOneId && this.selectTwoId && this.selectThreeId && this.selectFourId && this.selectFiveId);
    },
    ...mapGetters(['userThemeId']),
    variantSuccess() {
      if(this.userThemeId === 3) { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userThemeId === 3) { return 'danger-deuteranopia' }
      return 'danger'
    },
  },
  mounted() {
    this.getColumnValues()
  }
}
</script>

<style lang="scss">
.dropdown-menu {
  max-height: 30rem;
  overflow-y: auto;
}
</style>
